export const items = [
  {
    title: "Home",
    icon:  "rocket",
    path: "/home",
  },
  {
    title: "Confinus",
    icon: "bolt",
    path: "/confinus",
    isProduct: "Confinus",
    ability: "confinus",
    subitems: [
      {
        title: "Áreas",
        icon: "map",
        path: "/confinus/areas",
      },
      {
        title: "Dashboard",
        icon: "chart-column-big",
        path: "/confinus/dashboard",
      },
      {
        title: "Acontrole de Acesso",
        icon: "vault",
        path: "/confinus/access-control",
      },
      {
        title: "Autorizados",
        icon: "Id-card",
        path: "/confinus/autorizados",
      },
    ],
  },
  {
    title: "Altus",
    icon: "square-arrow-up",
    path: "/altus",
    isProduct: "Altus",
    ability: "altus",
    subitems: [
      {
        title: "Trabalhos Altura",
        icon: "locate",
        path: "/altus/areas-protegidas",
      },
      {
        title: "Dashboard",
        icon: "chart-column-big",
        path: "/altus/dashboard",
      },
      {
        title: "Acontrole de Acesso",
        icon: "Vault",
        path: "/altus/access-control",
      },
      {
        title: "Autorizados",
        icon: "Id-card",
        path: "/altus/autorizados",
      },
    ],
  },
  {
    title: "Protex",
    icon: "shield-plus",
    path: "/protex",
    isProduct: "Protex",
    ability: "protex",
    subitems: [
      {
        title: "Controle de APIs",
        icon: "hard-hat",
        path: "/protex/epi-control",
      },
      {
        title: "Estoque EPIs",
        icon: "archive-restore",
        path: "/protex/epi-estoque",
      },
    ],
  },
  {
    title: "e-Rdo",
    icon: "clipboard-plus",
    path: "/e-rdo",
    isProduct: "e-Rdo",
    ability: "e-rdo",
    subitems: [
      {
        title: "Diário de Obras",
        icon: "calendar-cog",
        path: "/e-rdo/diario",
      },
      {
        title: "Cadastros de Obras",
        icon: "building",
        path: "/e-rdo/obras",
      },
    ],
  },
  {
    title: "Certus",
    icon: "circle-check-big",
    path: "/certus",
    isProduct: "Certus",
    ability: "certus",
    subitems: [
      {
        title: "Certificados",
        icon: "file-badge",
        path: "/certus/certificados",
      },
      {
        title: "Instrutores",
        icon: "contact",
        path: "/certus/instrutores",
      },
      {
        title: "Turmas",
        icon: "clipboard-list",
        path: "/certus/turmas",
      },
      {
        title: "Provas",
        icon: "file-plus",
        path: "/certus/provas",
      },
      {
        title: "Alunos",
        icon: "graduation-cap",
        path: "/certus/alunos",
      },
    ],
  },
  {
    title: "Equipe",
    icon: "users",
    path: "/usuarios",
    ability: "user",
    subitems: [
      {
        title: "Usuários",
        icon: "square-user",
        ability: "user",
        path: "/usuarios",
      },
      {
        title: "Perfis",
        icon: "user-pen",
        path: "/usuarios/perfis",
      },
    ],
  },
];
