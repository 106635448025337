import axios from "axios";

// Função para obter o token do usuário
const accessToken = localStorage.getItem('accessToken');

// URL base da API
const BASE_URL = import.meta.env.VITE_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

export default api;

// Função para formatar a data para o formato ISO
const formatToISOString = (date: string | Date, isEndOfDay = false) => {
  const parsedDate = date instanceof Date ? date : new Date(date);
  if (isEndOfDay) {
    parsedDate.setHours(23, 59, 59, 999);
  } else {
    parsedDate.setHours(0, 0, 0, 0);
  }
  return parsedDate.toISOString();
};

// Key de datas para interceptação e formatação
const DATE_KEYS = ["createdAt", "inactivedAt", "updatedAt"];

interface queryParams {
  key: string;
  value: any;
}

// Serviço genérico para requisições GET
export const get = async <T>(controllerName: string, action = "", extraParams?: queryParams[]): Promise<T | undefined> => {
  if (!extraParams) return;

  const query = new URLSearchParams();
  for (let i = 0; i < extraParams.length; i++) {
    const param = extraParams[i];
    if (param.value !== null && param.value !== undefined && param.value.toString().trim() !== "") {
      if (DATE_KEYS.includes(param.key)) {
        if (param.value.length > 1) {
          query.append('startedAt', formatToISOString(param.value[0]));
          query.append('endedAt', formatToISOString(param.value[1], true));
        } else {
          query.append('startedAt', formatToISOString(param.value[0]));
        }
      } else {
        query.append(param.key, param.value);
      }
    }
  }
  let endpoint = `${controllerName}/${action}`;

  if (extraParams) {
    endpoint += `?${query}`;
  }

  const response = await api.get<T>(endpoint);

  return response.data;
};
