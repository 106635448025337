import { useQuery } from "@tanstack/react-query";
import Loader from "@/components/general-components/Loader";
import { getSelf } from "@/services/userService"; // Importa as funções
import Form from "./UserForm";

const Profile = () => {
  const { data: usersData, isLoading: isLoadingSelf } = useQuery({
    queryKey: ['selfUser'],
    queryFn: () => getSelf(),
  });

  if (isLoadingSelf) {
    return <Loader title="Carregando perfil..." />;
  }

  return (
    <>
      <div className="flex flex-col md:flex-row mb-4 items-start justify-between md:items-center">
        <div>
          <h1 className="text-xl font-bold">Meu Perfil</h1>
          <span className="text-gray-600 dark:text-gray-100">Atualizar meus dados</span>
        </div>
      </div>
      <div className="w-full md:w-1/2 mx-auto">
        <Form openSheet={() => console.log("fechou")} formData={usersData?.users[0]} onlyPassword={'both'} />
      </div>
    </>
  );
};

export default Profile;
